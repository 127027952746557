import jwtConfig from '@/auth/jwt/jwtConfig'
import axiosIns from '@/libs/axios'

export default {
  data() {
    const self = this
    return {
      froalaAttachments: [],
      froalaInstance: null,
      froalaConfig: {
        heightMax: 400,
        language: 'ru',
        key: process.env.VUE_APP_FROALA_LICENSE_KEY,
        attribution: false,
        quickInsertTags: [],
        toolbarButtons: {
          moreText: {
            // List of buttons used in the  group.
            buttons: ['bold', 'italic', 'underline', 'strikeThrough', 'subscript', 'superscript', 'fontFamily', 'fontSize', 'textColor', 'backgroundColor', 'inlineClass', 'inlineStyle', 'clearFormatting'],

            // Alignment of the group in the toolbar.
            align: 'left',

            // By default, 3 buttons are shown in the main toolbar. The rest of them are available when using the more button.
            buttonsVisible: 3,
          },

          moreParagraph: {
            buttons: ['alignLeft', 'alignCenter', 'formatOLSimple', 'alignRight', 'alignJustify', 'formatOL', 'formatUL', 'paragraphFormat', 'paragraphStyle', 'lineHeight', 'outdent', 'indent', 'quote'],
            align: 'left',
            buttonsVisible: 3,
          },

          moreRich: {
            buttons: ['insertLink', 'insertImage', 'insertVideo', 'insertTable', 'emoticons', 'fontAwesome', 'specialCharacters', 'embedly', 'insertHR'],
            align: 'left',
            buttonsVisible: 3,
          },

          moreMisc: {
            buttons: ['undo', 'redo', 'fullscreen', 'print', 'getPDF', 'spellChecker', 'selectAll', 'html', 'help'],
            align: 'right',
            buttonsVisible: 2,
          },
        },

        // Set the image upload parameter.
        imageDefaultWidth: 0,
        imageUploadParam: 'media_file',
        imageUploadURL: `${process.env.VUE_APP_BASE_URL}/media-files/upload`,
        imageUploadParams: { model_name: null, squad: null },
        imageUploadMethod: 'POST',
        // Set max image size to 5MB.
        imageMaxSize: 5 * 1024 * 1024,
        imageAllowedTypes: ['jpeg', 'jpg', 'png'],

        requestHeaders: {
          Authorization: `Bearer ${localStorage.getItem(jwtConfig.storageTokenKeyName)}`,
        },

        events: {
          // eslint-disable-next-line func-names
          'image.uploaded': function (response) {
            const froalaInstance = this
            const responseParsed = JSON.parse(response)
            const image = `/storage/${responseParsed.data.path}`
            self.froalaAttachments.push(responseParsed.data.id)
            froalaInstance.image.insert(image, false, null, froalaInstance.image.get(), response)
            return false
          },
          'image.removed': this.removed,
          'image.inserted': this.inserted,
          'image.replaced': this.replaced,
          'image.error': this.error,
        },
      },
    }
  },
  methods: {
    removed($img) {
      let image = $img.attr('src')
      image = image.replace(process.env.VUE_APP_IMAGE_BASE_URL, '')
      axiosIns({
        method: 'POST',
        url: '/media-files/remove',
        data: {
          image,
        },
      }).then(response => {
        console.log(response)
      }).catch(error => {
        console.log(error)
      })
      console.log(image)
    },
    inserted(/* $img, response */) {
      // console.log($img, response)
      // Image was inserted in the editor.
    },
    replaced(/* $img, response */) {
      // console.log($img, response)
      // Image was replaced in the editor.
    },
    error(/* error, response */) {
      // console.log(error, response)
    },
  },
}
