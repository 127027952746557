/* eslint-disable import/prefer-default-export */
/* eslint-disable guard-for-in */
/* eslint-disable no-plusplus */
import _ from 'lodash'

export class FormDataConverter {
  constructor(data) {
    this.data = data
    this.formData = new FormData()
  }

  getData() {
    const { formData } = this
    // eslint-disable-next-line no-restricted-syntax
    for (const item in this.data) {
      this.convertData(item, this.data[item], formData)
    }
    return formData
  }

  // eslint-disable-next-line class-methods-use-this
  convertData(key = '', value = '', formData = new FormData()) {
    if (typeof value !== 'undefined' && value !== null) {
      if (value instanceof Date) {
        formData.append(key, value.toISOString())
      } else if (value instanceof Array) {
        for (let i = 0; i < value.length; i++) {
          this.convertData(`${key}[${i}]`, value[i], formData)
        }
      } else if (typeof value === 'object' && !(value instanceof File)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const propertyName in value) {
          if (_.has(value, propertyName)) {
            this.convertData(
              key ? `${key}[${propertyName}]` : propertyName,
              value[propertyName],
              formData,
            )
          }
        }
      } else if (value instanceof File) {
        formData.append(key, value)
      } else if (typeof value === 'boolean') {
        if (value === true) {
          formData.append(key, 1)
        } else {
          formData.append(key, 0)
        }
      } else {
        formData.append(key, value.toString())
      }
    }
    return formData
  }
}
